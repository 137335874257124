.contact__container {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.contact__left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.contact__left h3 {
  font-weight: 600;
  font-size: 1.1rem;
  color: #462889;
}
.contact__left h2 {
  font-size: 2rem;
  width: 60%;
}
.contact__left h2 span {
  color: #462889;
}
.contact__right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}
.contact__right-inner {
  display: flex;
  align-items: center;
  gap: 1rem;

  cursor: pointer;
}
.contact__right-inner-left {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem;
  border-radius: 50%;
  border: none;
  transition: 0.3s;
  color: #462889;
  background-color: #fff;
}
.contact__right-inner-left:hover {
  color: #fff;
  background-color: #462889;
}
.contact__right-inner-left svg {
  width: 18px;
  height: 18px;
}
.contact__right-inner-right {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.contact__right-inner-right p {
  font-weight: 600;
  font-size: 1rem;
}
.contact__right-inner-right span {
  font-size: 0.9rem;
}

@media screen and (max-width: 322px) {
  .contact__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }
  .contact__left h2 {
    font-size: 1.6rem;
    width: 100%;
  }
  .contact__left p {
    font-size: 0.8rem;
  }
  .contact__right-inner-right span {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 323px) and (max-width: 376px) {
  .contact__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }
  .contact__left h2 {
    font-size: 2rem;
    width: 100%;
  }
  .contact__left p {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 377px) and (max-width: 426px) {
  .contact__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }
  .contact__left h2 {
    font-size: 2rem;
    width: 90%;
  }
}

@media screen and (min-width: 427px) and (max-width: 769px) {
  .contact__left h2 {
    font-size: 2rem;
    width: 100%;
  }
  .contact__left p {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .contact__left h2 {
    font-size: 2rem;
    width: 80%;
  }
}
