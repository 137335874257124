.profile__sidebar {
  /* position: fixed; */
  background-color: #fff;
  width: 269px;
  height: 100%;
  /* overflow-x: hidden; */
  font-family: "Poppins";
  transition: 0.3s;
}
.right-sidebar {
  right: 0;
}
.profile-bar__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;

  padding: 6.5rem 1rem;
  width: 100%;
}
.profile-bar__design {
  background-color: gray;
  height: 1px;
  width: 100%;
}
.profile-bar__head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;

  width: 100%;
}
.profile-bar__head img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
}
.user__name {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.user__name p {
  text-transform: capitalize;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
}
.profile-bar__head a {
  color: #462889;
  font-size: 14px;
}
.profile-bar__account,
.profile-bar__assets {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;
}
.profile-bar__account h3,
.profile-bar__assets h3 {
  color: #000;
  font-weight: 500;
  font-size: 15px;
}
.profile-bar__account div,
.profile-bar__assets div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}
.profile-bar__account div p,
.profile-bar__assets div p {
  color: grey;
  font-size: 13px;
}
.profile-bar__account div span {
  color: #000;
  font-size: 13px;
}
.profile-bar__assets div span {
  display: flex;
  align-items: center;
  gap: 0.3rem;

  color: #000;
  font-size: 13px;
}

@media screen and (max-width: 322px) {
  .profile__sidebar {
    display: none;
    /* position: fixed; */
    background-color: #fff;
    width: 190px;
    height: 100%;
    /* overflow-x: hidden; */
    font-family: "Poppins";
    transition: 0.3s;
  }
}

@media screen and (min-width: 323px) and (max-width: 376px) {
  .profile__sidebar {
    display: none;
    /* position: fixed; */
    background-color: #fff;
    width: 190px;
    height: 100%;
    /* overflow-x: hidden; */
    font-family: "Poppins";
    transition: 0.3s;
  }
}

@media screen and (min-width: 377px) and (max-width: 426px) {
  .profile__sidebar {
    display: none;
    /* position: fixed; */
    background-color: #fff;
    width: 190px;
    height: 100%;
    /* overflow-x: hidden; */
    font-family: "Poppins";
    transition: 0.3s;
  }
}

@media screen and (min-width: 427px) and (max-width: 769px) {
  .profile__sidebar {
    display: none;
    /* position: fixed; */
    background-color: #fff;
    width: 190px;
    height: 100%;
    /* overflow-x: hidden; */
    font-family: "Poppins";
    transition: 0.3s;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .profile__sidebar {
    /* position: fixed; */
    background-color: #fff;
    width: 190px;
    height: 100%;
    /* overflow-x: hidden; */
    font-family: "Poppins";
    transition: 0.3s;
  }

  .user__name p {
    color: #000;
    font-weight: 500;
    font-size: 14px;
  }
  .profile-bar__head a {
    color: #462889;
    font-size: 13px;
  }

  .profile-bar__account div p,
  .profile-bar__assets div p {
    color: #ffffff80;
    font-size: 11px;
  }
  .profile-bar__account div span {
    color: #e1dfeccc;
    font-size: 11px;
  }
  .profile-bar__assets div span {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: #e1dfeccc;
    font-size: 11px;
  }
}
