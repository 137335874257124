@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #f9f9fb;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

/* ======================== Container ===================== */
.container {
  margin-right: 8vw;
  margin-left: 8vw;
}

.section {
  padding: 6rem 0 3rem;
}

.small__section {
  padding: 3rem 0 3rem;
}

/*========== SCROLL BAR ==========*/
::-webkit-scrollbar {
  width: 0.6rem;
  background-color: var(--scroll-bar-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: #462889;
  border-radius: 0.5rem;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #382f4e;
}

@media screen and (max-width: 426px) {
  .container {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .small__section {
    padding: 2rem 0 2rem;
  }
}

@media screen and (min-width: 427px) and (max-width: 769px) {
  .container {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .small__section {
    padding: 3rem 0 3rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    margin-right: 6vw;
    margin-left: 6vw;
  }
}
