.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fbfbfd;
  backdrop-filter: blur(20px);
  z-index: 10;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  padding: 1rem 0;
}

.nav__close {
  display: none;
}
.logo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logo h3 {
  font-size: 1.3rem;
}

.nav__open {
  display: none;
}

.nav__menu {
  transition: 0.3s ease;
}

.remove-menu {
  left: -100%;
}

.nav__menu ul {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav__menu ul li a {
  font-weight: 600;
  font-size: 0.9rem;
  transition: 0.3s;
}
.nav__menu ul li a:hover {
  color: #462889;
}

.nav__auth {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.nav__auth a {
  font-size: 1rem;
  font-weight: 600;
  transition: 0.3s;
}

.nav__auth a:hover {
  color: #462889;
}

.nav__auth button {
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 500;
  color: #fff;
  background-color: #462889;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
}
.nav__auth button:hover {
  background-color: #34205e;
}

@media screen and (max-width: 322px) {
  .nav__open {
    display: block;
  }
  .nav__close {
    display: block;
    position: absolute;
    top: 3%;
    left: 8%;
  }
  .nav__menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    background-color: #fff;
    transition: 0.3s ease;
  }
  .remove-menu {
    left: -100%;
  }
  .nav__menu ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    margin-top: 5rem;
  }
  .nav__menu ul li a {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 323px) and (max-width: 376px) {
  .nav__open {
    display: block;
  }
  .nav__close {
    display: block;
    position: absolute;
    top: 3%;
    left: 8%;
  }
  .nav__menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    background-color: rgba(255, 255, 255, 0.9);
    transition: 0.3s ease;
  }
  .remove-menu {
    left: -100%;
  }
  .nav__menu ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    margin-top: 5rem;
  }
  .nav__menu ul li a {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 377px) and (max-width: 426px) {
  .nav__open {
    display: block;
  }
  .nav__close {
    display: block;
    position: absolute;
    top: 3%;
    left: 8%;
  }
  .nav__menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    background-color: #fff;
    transition: 0.3s ease;
  }
  .remove-menu {
    left: -100%;
  }
  .nav__menu ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    margin-top: 5rem;
  }
  .nav__menu ul li a {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 427px) and (max-width: 769px) {
  .nav__menu ul li a {
    font-weight: 600;
    font-size: 0.8rem;
    transition: 0.3s;
  }
  .nav__auth a {
    font-size: 0.9rem;
    font-weight: 600;
    transition: 0.3s;
  }
  .nav__auth a button {
    font-family: "Poppins";
    font-size: 0.8rem;
    font-weight: 500;
    color: #fff;
    background-color: #462889;
    padding: 0.8rem 1rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
}
