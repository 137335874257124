.auth {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f5e4ff;
  height: 100vh;
  width: 100%;
}
.register__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  margin: 0 35vw;
  width: 100%;
  padding: 2rem 2.5rem;
  border-radius: 10px;
  border: none;
  background-color: #fff;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}
.register__container h2 {
  font-weight: 500;
  font-size: 1.4rem;
  color: #462889;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  width: 100%;
}
.input__fields {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.input__fields label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #462889;
}
.input__fields div {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
.input__fields div svg:first-child {
  position: absolute;
  top: 35%;
  left: 3%;

  stroke: #462889;
  width: 18px;
  height: 18px;
}
.input__fields div svg:last-child {
  position: absolute;
  right: 4%;

  cursor: pointer;
  width: 20px;
  height: 20px;
}
.input__fields div input {
  font-family: "Poppins";
  font-size: 0.9rem;
  width: 100%;
  padding: 1rem 2.5rem;
  border-radius: 5px 5px 5px 0;
  border: 1px solid rgb(211, 211, 211);
}
.input__fields div input::placeholder {
  font-weight: 400;
  font-size: 0.85rem;
}
.input__fields div input:focus {
  outline-width: 1px;
  outline-color: #462889;
}
.forget__password {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.forget__password a {
  font-size: 0.8rem;
  font-weight: 500;
}
.form button {
  font-family: "Poppins";
  font-weight: 600;
  border-radius: 5px 5px 5px 0;
  border: none;
  color: #fff;
  background-color: #462889;
  padding: 1rem;
  cursor: pointer;
  transition: 0.3s;
}
.form button:hover {
  background-color: #3d1b52;
}

.auth__switch {
  font-size: 0.85rem;
  font-weight: 400;
}
.auth__switch a {
  color: #462889;
  font-weight: 500;
}
.auth__copyright {
  font-size: 0.85rem;
  font-weight: 400;
}

@media screen and (max-width: 322px) {
  .register__container h2 {
    font-weight: 500;
    font-size: 1.3rem;
    color: #462889;
  }
  .input__fields label {
    font-size: 0.85rem;
    font-weight: 500;
    color: #462889;
  }
  .input__fields div svg:first-child {
    position: absolute;
    top: 35%;
    left: 4%;
    stroke: #462889;
    width: 18px;
    height: 18px;
  }
  .input__fields div svg:last-child {
    position: absolute;
    right: 4.5%;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .register__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    margin: 0 4vw;
    width: 100%;
    padding: 1.5rem;
    border-radius: 10px;
    border: none;
    background-color: #fff;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
  }
  .auth__copyright {
    font-size: 0.8rem;
    font-weight: 400;
  }
}

@media screen and (min-width: 323px) and (max-width: 376px) {
  .register__container h2 {
    font-weight: 500;
    font-size: 1.3rem;
    color: #462889;
  }
  .input__fields label {
    font-size: 0.85rem;
    font-weight: 500;
    color: #462889;
  }
  .input__fields div svg:first-child {
    position: absolute;
    top: 35%;
    left: 4%;
    stroke: #462889;
    width: 18px;
    height: 18px;
  }
  .input__fields div svg:last-child {
    position: absolute;
    right: 4.5%;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .register__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    margin: 0 4vw;
    width: 100%;
    padding: 1.5rem;
    border-radius: 10px;
    border: none;
    background-color: #fff;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
  }
  .auth__copyright {
    font-size: 0.8rem;
    font-weight: 400;
  }
}

@media screen and (min-width: 377px) and (max-width: 426px) {
  .register__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    margin: 0 6vw;
    width: 100%;
    padding: 2rem;
    border-radius: 10px;
    border: none;
    background-color: #fff;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
  }
}

@media screen and (min-width: 427px) and (max-width: 769px) {
  .register__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    margin: 0 20vw;
    width: 100%;
    padding: 2rem 2.5rem;
    border-radius: 10px;
    border: none;
    background-color: #fff;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .register__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    margin: 0 25vw;
    width: 100%;
    padding: 2rem 2.5rem;
    border-radius: 10px;
    border: none;
    background-color: #fff;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
  }
}
